import { Link } from "gatsby";
import * as _ from "lodash";
import * as React from "react";
import Helmet from "react-helmet";
import ReactTooltip from "react-tooltip";
import "../blog/style.scss";
import "../blog/shared.scss";
import HubspotForm from "../components/hubspot-form";
import { Quote } from "../components/quote";
import "../components/quote/quote.scss";
import AmazeePage from "../components/layout";
import AmazeeIoMetadata from "../components/metadata";
import { convert_image_link } from "../components/reusable/types";
import { PageTitle } from "../components/title/title";
import Linked from "../utils/linkedItem";
import RichTextRender from "../utils/rich-text";
import usePreviewData from "../utils/usePreviewData";
import {
  BlogPostListingAuthor,
  getBlogPostImage,
  TagListing,
} from "./blog-post-listing";
import facebookImg from "../../static/images/facebook.svg";
import xImg from "../../static/images/x.svg";
import linkedinImg from "../../static/images/LinkedIn.svg";
import "./person.scss";

const moment = require("moment");

const BlogHeaderPost: React.FC<{ post: any }> = ({ post }) => {
  return (
    <header className="blog-post__header d-flex">
      <div className="row">
        <div className="col-12">
          <h1 className="blog__post__header__title">{post.title}</h1>
        </div>
        <div className="col-12 blog__post__metadata post-entry">
          <BlogPostListingAuthor
            readLength={post.read_time}
            date={post.posted_on}
            image={post.author_image}
            name={post.author_name}
            author2={post.author2}
          />
        </div>
      </div>
    </header>
  );
};

const SocialMediaLinks: React.FC<{ post: any; location: Location }> = ({
  post,
  location,
}) => {
  const urlEncoded = encodeURIComponent(location.href);
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${urlEncoded}`;
  const twitterShare = `https://x.com/home?status=${urlEncoded}`;
  const linkedInShare = `https://www.linkedin.com/shareArticle?mini=true&url=${urlEncoded}&title=&summary=&source=`;

  return (
    <div className="social-media-icons d-flex">
      <div
        className="cursor-pointer linkedin social-icon mr-4"
        onClick={() =>
          window.open(linkedInShare, "popup", "width=600,height=600")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          viewBox="0 0 19 19"
          role="img"
        >
          <path d="M17,17 L13.89343,17 L13.89343,12.1275733 C13.89343,10.9651251 13.87218,9.47069458 12.2781416,9.47069458 C10.660379,9.47069458 10.4126568,10.7365137 10.4126568,12.0434478 L10.4126568,17 L7.30623235,17 L7.30623235,6.98060885 L10.2883591,6.98060885 L10.2883591,8.3495072 L10.3296946,8.3495072 C10.7445056,7.56190587 11.7585364,6.7312941 13.2709225,6.7312941 C16.418828,6.7312941 17,8.80643844 17,11.5041407 L17,17 Z M3.80289931,5.61098151 C2.80647978,5.61098151 2,4.80165627 2,3.80498046 C2,2.80903365 2.80647978,2 3.80289931,2 C4.79669898,2 5.60434314,2.80903365 5.60434314,3.80498046 C5.60434314,4.80165627 4.79669898,5.61098151 3.80289931,5.61098151 Z M2.24786773,17 L2.24786773,6.98060885 L5.35662096,6.98060885 L5.35662096,17 L2.24786773,17 Z"></path>
        </svg>
      </div>
      <div
        className="cursor-pointer twitter social-icon mr-4"
        onClick={() =>
          window.open(twitterShare, "popup", "width=600,height=600")
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="19px" height="19px">
          <path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"/>
        </svg>
      </div>
       <div
        className="cursor-pointer facebook social-icon mr-4"
        onClick={() =>
          window.open(facebookShare, "popup", "width=600,height=600")
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19"
          viewBox="0 0 19 19"
          role="img"
        >
          <path d="M8.08865986,17 L8.08865986,10.2073504 L5.7890625,10.2073504 L5.7890625,7.42194226 L8.08865986,7.42194226 L8.08865986,5.08269399 C8.08865986,3.38142605 9.46779813,2.00228778 11.1690661,2.00228778 L13.5731201,2.00228778 L13.5731201,4.50700008 L11.8528988,4.50700008 C11.3123209,4.50700008 10.874068,4.94525303 10.874068,5.48583089 L10.874068,7.42198102 L13.5299033,7.42198102 L13.1628515,10.2073892 L10.874068,10.2073892 L10.874068,17 L8.08865986,17 Z"></path>
        </svg>
      </div>
    </div>
  );
};

const colSize = (num) => {
  try {
    if (num <= 4) {
      return 12 / num;
    } else {
      return 4;
    }
  } catch {
    return 6;
  }
};

const PrismicBlogPost: React.FC<{ body: Array<any> }> = ({ body }) => {
  console.log({body});
  
  return (
    <>
      {body.map((item, index) => {
        if (item.slice_type === "basic_content") {
          return (
            <div className="portion" key={index}>
              {RichTextRender(item.primary.content)}
            </div>
          );
        } else if (item.slice_type === "block_quote") {
          return (
            <blockquote className="portion" key={index}>
              {RichTextRender(item.primary.content)}
            </blockquote>
          );
        } else if (item.slice_type === "big_block_quote") {
          const { quote_text, quote_image, quote_subcopy } = item.primary;

          return (
            <Quote
              quote_text={quote_text}
              quote_image={quote_image}
              quote_subcopy={quote_subcopy}
            />
          );
        } else if (item.slice_type === "raw_html") {
          return (
            <React.Fragment key={index}>
              {item.primary.html_content && (
                <div
                  className="table_section"
                  dangerouslySetInnerHTML={{
                    __html: item.primary.html_content.text,
                  }}
                />
              )}
            </React.Fragment>
          );
        } else if (item.slice_type === "embedded_object") {
          return (
            <React.Fragment key={index}>
              {item.primary.javascript_snippet && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.primary.javascript_snippet,
                  }}
                />
              )}
              {item.primary.embed_url && (
                <iframe width="100%" src={item.primary.embed_url} />
              )}
            </React.Fragment>
          );
        } else if (item.slice_type === "hubspot_form") {
          const { form_id, portal_id } = item.primary;
          return (
            <HubspotForm key={index} formId={form_id} portalId={portal_id} />
          );
        } else if (item.slice_type === "multi_image") {
          const size = colSize(item.items.length);
          return (
            <div className="portion row multi-image" key={index}>
              {item.items.map((item, index: number) => {
                const image = (
                  <div
                    className="inner"
                    style={{ backgroundImage: `url('${item.image.url}')` }}
                  />
                );
                const converted_image_link = convert_image_link(
                  item.image_link
                );
                const inner =
                  _.isObject(item.image_link) &&
                  ((item.image_link.hasOwnProperty("url") &&
                    item.image_link.url !== "") ||
                    (item.image_link.hasOwnProperty("document") &&
                      item.image_link.document)) ? (
                    <Linked link_to={converted_image_link}>{image}</Linked>
                  ) : (
                    image
                  );
                return (
                  <div key={index} className={`col-${size} img`}>
                    {inner}
                  </div>
                );
              })}
            </div>
          );
        } else if (item.slice_type === "anchor_point") {
          const {
            anchor_id,
            anchor_link,
            anchor_link_text,
            anchor_link_target,
          } = item.primary;
          return (
            <>
              {anchor_id ? (
                <div id={anchor_id}></div>
              ) : anchor_link ? (
                <blockquote className="portion anchor-link">
                  <a href={anchor_link} target={anchor_link_target}>
                    {anchor_link_text ? anchor_link_text : anchor_link}
                  </a>
                </blockquote>
              ) : null}
            </>
          );
        } else if (item.slice_type === "authors_info") {
         const { items } = item;
         console.log({items});
                
          return (
            <div>
             <hr className="mt-20" />

              <div className="pt-10" aria-label="Authors Info">
                {items.map((_item, index) => (
                  <article key={index} className="mb-10">
                    <div className="flex gap-x-5 items-center mb-3">
                      <div
                        className="w-16 h-16 rounded-full bg-cover bg-center"
                        style={{ backgroundImage: `url('${_item.author_details?.document?.data.image.url}')` }}
                      />
                      <div className="flex flex-col font-medium text-base blog_author_section">
                        <div className="text-black">By {_item.author_details?.document?.data.name}</div>
                        <div>
                          {_item.author_details?.document?.data.job_description || _item?.job_description ? 
                          
                          <span>{`${_item.author_details?.document?.data.job_description || _item?.job_description}, `}</span> : null }
                          <a
                          href={_item.company_url.url}
                          target={_item.company_url.target || null}
                          className="text-amazee-deep-sky-blue text-base font-medium">{_item.company}</a>
                        </div>
                      </div>
                    </div>
                    <div className="font-light text-black blog__post__content">
                       {RichTextRender(_item.short_content)}
                    </div>
                  </article>
                ))}
              </div>
            </div>
          );
        } else {
          console.error("Unhandled component in prismic blog", item.type);
          return null;
        }
      })}
    </>
  );
};

const massagePreviewData = (previewData) => ({
  ...previewData,
  body: previewData.body1,
  summary: previewData.summary,
  tag: previewData.tag.name,
  canonical_url: previewData.canonical_url,
  slug: previewData.uid,
  title: previewData.title,
  meta_title: previewData.meta_title,
  posted_on: moment(previewData.post_date, "YYYY-MM-DD").format("MMM DD, YYYY"),
  author_name: previewData.author.document.data.name,
  author_image: previewData.author.document.data.image.url,
  read_time: previewData.minutes_read_time,
  images: [
    {
      path: previewData.metadata_image.url,
    },
  ],
});

const BlogPostPage: React.FC<{ pageContext: any; location: any }> = ({
  pageContext,
  location,
}) => {
  const data = usePreviewData(
    pageContext.data,
    "prismicBlogPost",
    true,
    massagePreviewData
  );
  const {
    legacy,
    topLevelBlogData: { tags },
    suggestedPosts,
  } = pageContext;

  const currentTagName = data.tag;
  const currentTag = _.find(tags, (t) => t.name === currentTagName);

  let mainImage =
    data && data.images && data.images[0] && data.images[0].path
      ? { url: data.images[0].path }
      : undefined;
  mainImage =
    mainImage ||
    (data && data.images && data.images[0] && data.images[0].url
      ? { url: data.images[0].url }
      : undefined);

  const excerpt = data?.summary || undefined;
  const title = data?.meta_title ? data?.meta_title : data?.title;
  
  return (
    <AmazeePage location={location}>
      <div id="blog-post" className="blog">
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/js/all.min.js"></script>
          {data.canonical_url && (
            <link rel="canonical" href={data.canonical_url} />
          )}
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />

        <AmazeeIoMetadata
          title={title}
          page_meta_thumbnail={mainImage}
          page_meta_description={excerpt}
          location={location}
        />
        <TagListing tags={tags} currentTag={currentTag} />



        <div className="container pt-4 mb-24">
          <div className="row d-flex justify-center">
            <div className="col-12 col-md-8 col-xl-7">
              <div className="row">
                <div className="col-12 d-none d-flex">
                  <div className="blog__post">
                    <BlogHeaderPost post={data} />
                    {legacy && (
                      <div
                        className="content content--legacy"
                        dangerouslySetInnerHTML={{ __html: pageContext.html }}
                      />
                    )}
                    {!legacy && (
                      <div className="content content--prismic">
                        <PrismicBlogPost body={data.body1 || data.body} />
                      </div>
                    )}

                    {/* // TODO: Handle prismic */}
                    <hr className="mt-16" />
                    <div className="blog-footer d-flex justify-between mt-8 mb-24">
                      <SocialMediaLinks post={data} location={location} />
                      {currentTag && (
                        <Link
                          to={`/blog/categories/${currentTag.slug}/page/1`}
                          className="current-tag"
                        >
                          {currentTagName}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 col-xl-8">
              <div className="blog__suggested-posts">
                <div className="d-flex justify-between">
                  <div
                    className="text"
                    style={{ fontWeight: 600, fontSize: 18 }}
                  >
                    Recent Posts
                  </div>
                  <a href="/blog" className="text">
                    See All
                  </a>
                </div>
                <div className="suggested-posts__wrapper">
                  {suggestedPosts.map((p: any, index: number) => {
                    const url = `/blog/post/${p.slug}`;
                    return (
                      <a className="suggested-post" key={index} href={url}>
                        <img className="w-full" src={getBlogPostImage(p)} />
                        <div className="suggested-post__title">
                          {p.data.title}
                        </div>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ReactTooltip id="writer" place="top" type="dark" effect="solid">
          Writer
        </ReactTooltip>
      </div>
    </AmazeePage>
  );
};

export default BlogPostPage;
